<template>
  <div class="flex flex-col justify-start items-center font-Inter mx-8 mt-6">
    <div class="bg-white w-1/2 p-8">
      <h2 class="mb-3 text-lg font-medium">Modification du mot de passe</h2>

      <el-form :rules="rulesForm" ref="ruleForm" :model="passwordForm">
        <el-form-item label="Nouveau de mot de passe" prop="password">
          <el-input
            type="password"
            v-model="passwordForm.password"
            autocomplete="off"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="Confirmer le mot de passe" prop="checkPassword">
          <el-input
            type="password"
            v-model="passwordForm.checkPassword"
            autocomplete="off"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="flex justify-between">
        <el-button
          type="primary"
          :loading="loading"
          :disabled="loading"
          @click="updatePassword('ruleForm')"
          >Enregistrer</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { updatePassword } from "@/api/user";
export default {
  name: "PASSWORD",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Veuillez entrer le mot de passe"));
      } else {
        if (this.passwordForm.checkPassword !== "") {
          this.$refs.ruleForm.validateField("checkPassword");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Veuillez entrer à nouveau le mot de passe"));
      } else if (value !== this.passwordForm.password) {
        callback(new Error("Les deux entrées ne correspondent pas!"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      passwordForm: {
        password: "",
        checkPassword: "",
      },
      rulesForm: {
        password: [
          {
            validator: validatePass,

            trigger: "change",
          },
        ],
        checkPassword: [
          {
            validator: validatePass2,
            trigger: "change",
          },
        ],
      },
      loading: false,
    };
  },
  mounted() {},
  methods: {
    updatePassword(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.loading = true;
          updatePassword(this.passwordForm).then(() => {
            setTimeout(async () => {
                this.loading = false;
                await this.$store.dispatch("user/logout");
                this.$router.push({
                    path: "/signin",
                });
            }, 1.5 * 1000);
          });
        }
      });
    },
  },
};
</script>

<style></style>
